import { useSearchParams } from "react-router-dom"
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from "react-hook-form";
import '../form/form-register.css'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import API_URL from '../../Api/index'
import Swal from "sweetalert2"
import { useParams } from "react-router-dom"
import banner from '../../assets/img/level3/Banner_DaftarWEFSS.png'
import {Row, Col} from 'reactstrap';

const FormRegister = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const [btnLoading, setBtnLoading] = useState(false)

    const [searchParams] = useSearchParams();
    const type = searchParams.get("edufest");

    const onSubmit = async (data) => {
        setBtnLoading(true);
        await API_URL.baseUrl.post('checkout-konsultasi', data)
        .then(res => {
            if(res.status === 200){
                window.snap.pay(res.data, {
                  onSuccess: function(result){window.location.reload(false);},
                  onPending: function(result){window.location.reload(false);},
                  onError: function(result){
                    console.log('error');console.log(result);
                  },            
                  onClose: function(){
                    console.log('customer closed the popup without finishing the payment');
                  }
                })
            } else {
                Swal.fire({
                    title: 'Registrasi Failed',
                    icon: 'failed',
                    type: 'failed',
                    confirmButtonColor: '#3085d6',
                    text: 'Register Failed',  
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
            }
            setBtnLoading(false)
        })
        .catch(err => console.log(err))
    }

    useEffect(() => {
        
    },[])

    return (
        <>
            <div id="register" className='form-register' style={{marginBottom: '50px'}}>
                <h2>Form Pendaftaran</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-input">
                        <label htmlFor="email" className="form-label">Email </label>
                        <input {...register("email", {required:'Email Wajib diisi', pattern: {value:/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, message: 'Email tidak valid'}})}  type="text" className={`form-control ${errors.email?.message && 'is-invalid'}`} id="email" placeholder="name@example.com" />
                        {errors.email?.message && (<div className="invalid-feedback">{errors.email?.message}</div>) }
                    </div>
                    <div className="form-input">
                        <label htmlFor="nama" className="form-label">Nama</label>
                        <input {...register("name", {required:'Nama Lengkap Wajib Diisi'})} type="text" className={`form-control ${errors.name?.message && 'is-invalid'}`} id="nama" placeholder="Nama Lengkap" />
                        {errors.name?.message && (<div className="invalid-feedback">{errors.name?.message}</div>) }
                    </div>
                    <div className="form-input">
                        <label htmlFor="phone" className="form-label">No Whatsapp</label>
                        <Controller
                            control={control}
                            name="phone"
                            rules={{ required: 'Nomor Whatsapp Wajib Diisi'}}
                            render={({ field: { ref, ...field } }) => (
                                <PhoneInput
                                    {...field}
                                    inputExtraProps={{
                                    ref,
                                    required: true,
                                    }}
                                    country={"id"}
                                    countryCodeEditable={false}
                                    inputClass={`${errors.phone?.message && 'input-invalids'}`}
                                    inputStyle={{width:'100%', height: '45px'}}
                                />
                            )}
                        />
                        {errors.phone?.message && (<div className="text-invalids">{errors.phone?.message}</div>) }
                    </div>
                    <div className="form-input">
                        <button disabled={btnLoading ? true : false} type='submit' className='btn btn-register'>{btnLoading ? 'Daftar...' : 'Daftar'}</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default FormRegister